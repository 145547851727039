import {APIProvider, Map} from '@vis.gl/react-google-maps';
import {LakeUnionData} from "./LakeUnionData";
import React, {Dispatch, SetStateAction} from "react";
import {MapMouseEvent} from "@vis.gl/react-google-maps/src/components/map/use-map-events";
import {UserLocation} from "./UserLocation";
import {LaunchMarkers} from "./LaunchMarkers";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

type Props = {
    features: LakeUnionData;
    selectedFeature: string;
    setSelectedFeature: Dispatch<SetStateAction<string>>;

    showMyLocation: boolean;
    showMarkets: boolean;
    showRestaurants: boolean;
    showBars: boolean;
};

export function LakeMap({features, selectedFeature, setSelectedFeature, showMyLocation, showMarkets, showRestaurants, showBars}: Props) {
    const view = features.view;

    return (
        <div id="daily-map">
            <APIProvider apiKey={API_KEY}>
                <Map
                    defaultCenter={view.position}
                    defaultZoom={view.zoom}
                    mapId={"LAKE_UNION_GUIDE"}
                    // TODO: this is only for debugging!!!
                    onClick={e => mouseClickEvent(e, true)}
                >
                </Map>
                <LaunchMarkers features={features}
                               selectedFeature={selectedFeature}
                               setSelectedFeature={setSelectedFeature}
                               showMyLocation={showMyLocation}
                               showMarkets={showMarkets}
                               showRestaurants={showRestaurants}
                               showBars={showBars}
                />
                <UserLocation
                    showUserLocation={showMyLocation}
                />
            </APIProvider>
        </div>
    );
}

function mouseClickEvent(e: MapMouseEvent, isDebug: boolean) {
    if (isDebug) {
        console.log(e.detail.latLng);
    }
}