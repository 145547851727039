import {AdvancedMarker, Pin} from "@vis.gl/react-google-maps";
import React, {Dispatch, SetStateAction} from "react";
import {FeatureDetails, FeatureType} from "./FeatureDetails";

const LaunchPin = (<Pin background={'#d9fff5'} glyphColor={'#d9fff5'} borderColor={'#383814'}>⛱️</Pin>);
const MarketPin = (<Pin background={'#ffb4b4'} glyphColor={'#ffb4b4'} borderColor={'#383814'}>🍅</Pin>);
const RestaurantPin = (<Pin background={'#0ca4f6'} glyphColor={'#0ca4f6'} borderColor={'#383814'}>🍴 </Pin>);
const BarPin = (<Pin background={'#ffed5f'} glyphColor={'#ffed5f'} borderColor={'#383814'}>🍺</Pin>);
const CafePin = (<Pin background={'#ffffff'} glyphColor={'#ffffff'} borderColor={'#383814'}>☕</Pin>);
const RestroomPin = (<Pin background={'#ffffff'} glyphColor={'#ffffff'} borderColor={'#383814'}>🚻</Pin>);
const SelectedFeaturePin = (<Pin background={'#d9fff5'} glyphColor={'#000'} borderColor={'#000'}/>);
type Props = {
    position: { lat: number, lng: number };
    selectedFeature: string;
    setSelectedFeature: Dispatch<SetStateAction<string>>;
    name: string;
    id: string;
    launchDetails: FeatureDetails;
};

export function FeatureMarker({position, selectedFeature, setSelectedFeature, name, id, launchDetails}: Props) {
    let pin = SelectedFeaturePin;
    switch (launchDetails.type) {
        case FeatureType.PUBLIC_LAUNCH:
            pin = LaunchPin;
            break;
        case FeatureType.MARKET:
            pin = MarketPin;
            break;
        case FeatureType.RESTAURANT:
            pin = RestaurantPin;
            break;
        case FeatureType.BAR:
            pin = BarPin;
            break;
        case FeatureType.COFFEE_SHOP:
            pin = CafePin;
            break;
        case FeatureType.PUBLIC_RESTROOM:
            pin = RestroomPin;
            break;
    }
    if(selectedFeature === id) {
        pin = SelectedFeaturePin
    }
    return (
        <AdvancedMarker
            key={name}
            title={name}
            position={position}
            onClick={() => {
                setSelectedFeature(id);
            }}>
            {pin}
        </AdvancedMarker>
    );
}
