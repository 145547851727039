import React, {Dispatch, SetStateAction} from "react";
import {LakeUnionData} from "./LakeUnionData";
import {FeatureMarker} from "./FeatureMarker";

type Props = {
    features: LakeUnionData;
    selectedFeature: string,
    setSelectedFeature: Dispatch<SetStateAction<string>>;

    showMyLocation: boolean;
    showMarkets: boolean;
    showRestaurants: boolean;
    showBars: boolean;
};

export function LaunchMarkers({features, selectedFeature, setSelectedFeature}: Props) {

    let launchMarkers = [];
    for (const [key, value] of Object.entries(features.launches)) {
        launchMarkers.push(<FeatureMarker
            key={key}
            position={value.position}
            selectedFeature={selectedFeature}
            setSelectedFeature={setSelectedFeature}
            name={value.name}
            launchDetails={value}
            id={key}/>
        );
    }
    return (
        <div>
            {launchMarkers}
        </div>
    );
}
