import {Dispatch, SetStateAction} from "react";
import styled from "styled-components";

type Props = {};

const TeamHeaderDiv = styled.div`
  width: 100%;
  font-size: x-large;
  text-align: justify-all;
  font-family: "Future Condensed",sans-serif;
`

export function AppHeader({}: Props) {
    return (
        <div>
            <TeamHeaderDiv>
                Lake Union Kayak & Paddle Board Guide
            </TeamHeaderDiv>
        </div>
    );
}
