import {FeatureDetailRecords} from "./FeatureDetails";
import prodData from './data.json';

export type LakeUnionData = {
    view: MapView;
    launches: FeatureDetailRecords;
};

export type MapView = {
    position: google.maps.LatLngLiteral;
    zoom: number;
}

// TODO: replace this test data with an actual file/backend
export async function defaultData(): Promise<LakeUnionData> {
    return prodData;
}
