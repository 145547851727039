export type FeatureDetails = {
    name: string,
    position: { lat: number, lng: number },
    verified: boolean,
    type: FeatureType,
    location: FeatureLocation,
    url?: string,
    googleMapsUri?: string,
    address?: string,
    placeId?: string,
    photoRef?: string,
    image?: string,
    comment?: string,
};

export enum FeatureType {
    PUBLIC_LAUNCH,
    MARKET,
    RESTAURANT,
    BAR,
    COFFEE_SHOP,
    PUBLIC_RESTROOM
}
export enum FeatureLocation {
    LAKE_UNION,
    SALMON_BAY,
    SHIP_CANAL,
    PORTAGE_BAY,
    UNION_BAY
}

export type FeatureDetailRecords = Record<string, FeatureDetails>;
