import React, {useEffect, useState} from "react";
import {defaultData, LakeUnionData} from "./LakeUnionData";
import {AppHeader} from "./AppHeader";
import {LakeMap} from "./LakeMap";
import {FeatureFocus} from "./FeatureFocus";
import {NoSelectedFeature} from "./NoSelectedFeature";

export function LakeUnionGuide() {
    const [features, setFeatures] = useState<LakeUnionData>();
    const [showMyLocation, setShowMyLocation] = useState<boolean>(true);
    const [showMarkets, setShowMarkets] = useState<boolean>(false);
    const [showRestaurants, setShowRestaurants] = useState<boolean>(false);
    const [showBars, setShowBars] = useState<boolean>(false);
    const [selectedFeature, setSelectedFeature] = useState<string>("");
    useEffect(() => {
        defaultData().then(fetchedFeatures => {
            setFeatures(fetchedFeatures);
        });
        return () => {
        }
    }, [features]);
    if (!features) {
        return null
    }
    const featureDetails = features.launches[selectedFeature];
    let barFocus = null;
    if (featureDetails) {
        barFocus = (<FeatureFocus
            selectedFeature={selectedFeature}
            setSelectedFeature={setSelectedFeature}
            featureDetails={featureDetails}
        />);
    } else {
        barFocus = <NoSelectedFeature
            setSelectedFeature={setSelectedFeature}
        />
    }

    return (
        <div className="App">
            <AppHeader />
            <LakeMap
                features={features}
                selectedFeature={selectedFeature}
                setSelectedFeature={setSelectedFeature}
                showMyLocation={showMyLocation}
                showMarkets={showMarkets}
                showRestaurants={showRestaurants}
                showBars={showBars}
            />
            {barFocus}
        </div>
    );
}
