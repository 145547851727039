import './App.css';
import {LakeUnionGuide} from "./components/LakeUnionGuide";

function App() {
    return (
        <LakeUnionGuide />
    )
}

export default App;
