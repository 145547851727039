import React, {Dispatch, SetStateAction} from "react";
import styled from "styled-components";


type Props = {
    setSelectedFeature: Dispatch<SetStateAction<string>>;
    // featureDetails: LaunchDetails,
};

const TownDiv = styled.div`
  color: white;
  margin: 2px;
  background: #000000;
`;
const BarDiv = styled.div`
  margin: 2px 1px;
  background: #e1caf8;

  &:hover {
    background: #ffffff;
  }
`;
const SponsorDiv = styled.div`
  margin: 2px 1px 30px;
  background: #00ccff;
`;
const BarNameDiv = styled.div`
  font-size: large;
`;
const BarCommentsDiv = styled.div`
`;

const BarTable = styled.table`
  width: 100%;
`;
const BarTr = styled.tr`
  width: 100%;
  vertical-align: top;
`;
const BarTd = styled.td`
  width: 50%;
`;

export function NoSelectedFeature({setSelectedFeature}: Props) {

    return (
        <div></div>
    )
}
